import React from "react";
import PropTypes from "prop-types";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useAlert } from "../../context/AlertContext";
import { Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Layout from ".";

import { Autoplay } from "swiper";
import { AgentProvider } from "../../context/AgentContext";

function ScreenLayout({ titles, screens, className }) {
  const handle = useFullScreenHandle();
  const button = React.useRef(null);
  const { dispatch } = useAlert();
  const [title, setTitle] = React.useState(titles[0]);

  const handleFullScreen = () => handle.enter();

  const closeAlert = () => {
    dispatch({
      open: false,
    });
  };

  React.useEffect(() => {
    //
  }, []);

  return (
    <AgentProvider>
      <div className={`position-relative${className ? " " + className : ""}`}>
        <Button
          variant="danger"
          className="btn-fullscreen"
          ref={button}
          onClick={handleFullScreen}
        >
          Plein ecran
        </Button>
        <FullScreen handle={handle}>
          <Layout title={title}>
            <Swiper
              className="h-100"
              modules={[Autoplay]}
              autoplay={{
                delay: 1000 * 60 * 60 * 24,
              }}
              onSlideChange={(e) => setTitle(titles[e.activeIndex])}
            >
              {screens ? (
                screens.map((item, index) => (
                  <SwiperSlide key={index}>{item}</SwiperSlide>
                ))
              ) : (
                <></>
              )}
            </Swiper>
          </Layout>
        </FullScreen>
      </div>
    </AgentProvider>
  );
}

ScreenLayout.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  screens: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
};

export default ScreenLayout;
