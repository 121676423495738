import React from "react";
import PropTypes from "prop-types";

import "../../../scss/agent.scss";

const defaultImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

function Performance({
  image,
  nom,
  acd,
  appels,
  hasRibbon = false,
  redTime = false,
}) {
  return (
    <div className="d-flex agent mb-3">
      <div className={`img-profile me-3 ${hasRibbon ? "ribbon" : ""}`}>
        <img
          src={
            image === "https://hive404.my3cx.fr/user_images/" ||
            image === "https://hive404.my3cx.fr/user_images/null"
              ? defaultImage
              : image
          }
          alt=""
        />
      </div>
      <div className="w-100">
        <strong>{nom}</strong>
        <br />
        <div className="d-flex w-100">
          <div className="me-auto">
            <small>ACD</small>
            <br />
            <strong style={redTime ? { color: "#F53030" } : {}}>{acd}</strong>
          </div>
          <div>
            <small>Appels traités</small>
            <br />
            <strong>{appels}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

Performance.propTypes = {
  image: PropTypes.string,
  nom: PropTypes.string,
  acd: PropTypes.string,
  appels: PropTypes.number,
  hasRibbon: PropTypes.bool,
  redTime: PropTypes.bool,
};

export default Performance;
